import copy from 'copy-to-clipboard'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

import statesAndCities from '@/assets/statesAndCities.json'
import Divider from '@/components/Divider'
import { useFetch } from '@/hooks/useFetch'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import { combineDateAndTime } from '@/utils/datetime'
import {
    BarChartOutlined as CopyPublicLinkIcon,
    EditOutlined as EditIcon,
    AspectRatioOutlined as AccessCampaignIcon,
    CloseOutlined as CloseIcon
} from '@mui/icons-material'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    FormControlLabel,
    Switch,
    IconButton,
    Link,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    SelectChangeEvent,
    Chip
} from '@mui/material'
// import { styled } from '@mui/styles'
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import useStyles from './styles'
import 'dayjs/locale/pt-br'

/* const BootstrapInput = styled(TextField)(() => ({
    '& ::-webkit-calendar-picker-indicator': {
        filter: 'invert(1)'
    },
    '& .MuiInputLabel-root': {
        color: '#9c9c9c !important'
    },
    '& .MuiOutlinedInput-root': {
        color: '#9c9c9c !important',
        '& fieldset': {
            borderColor: '#9c9c9c'
        }
    },
    '& .MuiFormControl-root': {
        // width: '284px !important',
        height: '56px !important'
    }
})) */

interface ICompany {
    uuid: string
    name: string
    corporateName: string
    streamId: number
    address: {
        state: string,
        city: string,
    }
}

export interface ICampaign {
    uuid?: string
    logoBase64?: string | null;
    logoUrl?: string
    title?: string
    description?: string
    whatsappReportPhonenumbers?: string[]
    enabled?: boolean
    beginAt?: string
    finishAt?: string
    hourStart?: string
    hourEnd?: string
    weekdays?: string[]
    companies?: ICompany[]
    companyUuids?: string[]
    createdAt?: string
    updatedAt?: string
    companyNames?: string[]
}

interface IWeekdays {
    label: string
    value: string
}

const weekDaysMap = [
    { label: 'SEGUNDA-FEIRA', value: 'monday' },
    { label: 'TERÇA-FEIRA', value: 'tuesday' },
    { label: 'QUARTA-FEIRA', value: 'wednesday' },
    { label: 'QUINTA-FEIRA', value: 'thursday' },
    { label: 'SEXTA-FEIRA', value: 'friday' },
    { label: 'SÁBADO', value: 'saturday' },
    { label: 'DOMINGO', value: 'sunday' }
]

const Campaigns: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    // const [allCompaniesChecked, setAllCompaniesChecked] = useState<boolean>(false)
    const [companies, setCompanies] = useState<ICompany[]>([])
    const [campaigns, setCampaigns] = useState<ICampaign[]>([])
    const [campaign, setCampaign] = useState<ICampaign>({
        enabled: true,
        companyUuids: [],
        companyNames: [],
        whatsappReportPhonenumbers: [],
    } as ICampaign)
    const [openedCampaignDialog, setOpenedCampaignDialog] = useState<boolean>(false)
    const [uploadedLogo, setUploadedLogo] = useState<File | undefined>()
    const [loadingCampaign, setLoadingCampaign] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<string>('')
    const [weekDays, setWeekDays] = useState<IWeekdays[]>([])

    useEffect(() => {
        if (campaign.beginAt && campaign.finishAt) {
            const startDate = dayjs(campaign.beginAt)
            const endDate = dayjs(campaign.finishAt)
            const days = getWeekDaysInRange(startDate, endDate)
            setWeekDays(days)
            setCampaign(prevCampaign => {
                const updatedWeekdays = days
                    .filter(day => prevCampaign?.weekdays?.includes(day.value))
                    .map(day => day.value)

                return {
                    ...prevCampaign,
                    weekdays: updatedWeekdays
                }
            })
        }
    }, [campaign.beginAt, campaign.finishAt])

    const openCampaignDialog = (campaign?: ICampaign) => {
        if (campaign) {
            const { companies = [] } = campaign
            campaign.companyUuids = companies.map(company => company.uuid) || []
            campaign.companyNames = companies.map(company => getCompanyIdentifier(company)) || []
        }

        setCampaign(campaign ?? {
            enabled: true,
            companyUuids: [],
            companyNames: [],
            whatsappReportPhonenumbers: [],
            hourStart: '00:00',
            hourEnd: '23:59'
        } as ICampaign)

        setUploadedLogo(undefined)
        setOpenedCampaignDialog(true)
    }

    const handleCloseCampaignDialog = () => {
        setOpenedCampaignDialog(false)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            event.preventDefault()

            const newNumber = event.target.value.trim()

            if (campaign.whatsappReportPhonenumbers?.includes(newNumber)) {
                return
            }

            if ((campaign.whatsappReportPhonenumbers || []).length >= 10) {
                return
            }


            setCampaign((prevCampaign) => {
                const updatedNumbers = [
                    ...(prevCampaign.whatsappReportPhonenumbers || []),
                    newNumber
                ]
                    .map((number) => number.replace(/[^0-9]/g, ''));

                return {
                    ...prevCampaign,
                    whatsappReportPhonenumbers: updatedNumbers
                }
            })

            event.target.value = ''
        }
    }

    const handleDelete = (numberToDelete: string) => {
        setCampaign((prevCampaign) => ({
            ...prevCampaign,
            whatsappReportPhonenumbers: (prevCampaign.whatsappReportPhonenumbers || []).filter(
                (number) => number !== numberToDelete
            )
        }))
    }

    const handleChangeCampaignEnabled = async (campaign: ICampaign) => {
        try {
            const filteredCampaign = campaigns.find(c => c.uuid === campaign.uuid);

            const isEnabled = filteredCampaign ? !filteredCampaign.enabled : campaign.enabled;

            await ApiService.put(`campaign|intranet/campaigns/${campaign.uuid}`, {
                enabled: isEnabled
            })

            getCampaigns()
        } catch (error: any) {
            enqueueSnackbar(error?.data as string ?? 'Ocorreu um erro ao criar a campanha.', {
                variant: 'error'
            })
        }
    }

    const getCompanies = async () => {
        const { data } = await ApiService.get('campaign|intranet/campaigns/companies') // ICompany[]

        setCompanies(data)
    }

    const getCampaigns = async () => {
        const { data } = await ApiService.get('campaign|intranet/campaigns') // ICampaign[]

        setCampaigns(data)
    }

    const saveCampaign = async () => {
        try {
            setLoadingCampaign(true)

            const path = 'campaign|intranet/campaigns'
            const body = {
                ...campaign,
                beginAt: combineDateAndTime(campaign.beginAt, campaign.hourStart),
                finishAt: combineDateAndTime(campaign.finishAt, campaign.hourEnd)
            }

            if (campaign.uuid) await ApiService.put(path + `/${campaign.uuid}`, body)
            else await ApiService.post(path, body)

            handleCloseCampaignDialog()
        } catch (error: any) {
            enqueueSnackbar(error?.data as string ?? 'Ocorreu um erro ao criar a campanha.', {
                variant: 'error'
            })
        }

        getCampaigns()

        setLoadingCampaign(false)
    }

    useDidMount(getCompanies)

    useFetch<ICampaign[]>(
        'campaign|intranet/campaigns',
        (data: ICampaign[]) => {
            setCampaigns(data)
        }
    )

    const getCompanyIdentifier = (company: ICompany): string => {
        const stateAbbreviation = _.find(statesAndCities, { name: company.address?.state })?.initials
        return `${company.name} | ${company.address.city} - ${stateAbbreviation} (${company.streamId})`
    }

    const handleAddCompanyToCampaign = (event: SelectChangeEvent<string[]>): void => {
        const selectedValues = event.target.value as string[]
        const companiesMap = new Map(companies.map(company => [company.uuid, company]))

        const { finalNames, finalUuids } = selectedValues.reduce((acc, value) => {
            const company = companiesMap.get(value) || companies.find(c => getCompanyIdentifier(c) === value)

            if (company) {
                const companyIdentifier = getCompanyIdentifier(company)

                if (acc.finalNames.includes(companyIdentifier)) {
                    acc.finalNames = acc.finalNames.filter(name => name !== companyIdentifier)
                    acc.finalUuids = acc.finalUuids.filter(uuid => uuid !== company.uuid)
                } else {
                    acc.finalNames.push(companyIdentifier)
                    acc.finalUuids.push(company.uuid)
                }
            }

            return acc
        }, { finalNames: [] as string[], finalUuids: [] as string[] })

        setCampaign(prevCampaign => ({
            ...prevCampaign,
            companyUuids: finalUuids,
            companyNames: finalNames
        }))
    }

    const copyPublicCampaignMetricsLink = (campaign: any) => {
        copy(`${process.env.REACT_APP_FRONTEND_METRICS}/c/${campaign.code}`)

        enqueueSnackbar('Link copiado com sucesso.', {
            variant: 'success'
        })
    }

    function handleDayChange({ target }: any) {
        const formArray = target.value
        setCampaign({ ...campaign, weekdays: formArray })
    }

    function sortWeekDays(weekDays: IWeekdays[]): IWeekdays[] {
        const order = weekDaysMap.map(day => day.value)
        return weekDays.sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value))
    }

    function getWeekDaysInRange(start: Dayjs, end: Dayjs): IWeekdays[] {
        let current = start.startOf('day')
        const daysOfWeek: IWeekdays[] = []

        while (current.isBefore(end, 'day') || current.isSame(end, 'day')) {
            const fullDay = current.locale('pt-br').format('dddd')
            const abbreviatedDay = weekDaysMap.find(day => day.label.toLowerCase() === fullDay.toLowerCase())
            if (abbreviatedDay) {
                daysOfWeek.push(abbreviatedDay)
            }
            current = current.add(1, 'day')

            if (daysOfWeek.length === 7) {
                break
            }
        }

        return sortWeekDays(daysOfWeek)
    }

    const handleSelectLogo = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event.target.files?.[0]
            if (!file) return

            const validFormats = ['image/png', 'image/jpeg', 'image/jpg']
            if (!validFormats.includes(file.type)) {
                enqueueSnackbar('Formato inválido. Apenas PNG, JPEG e JPG são permitidos.', { variant: 'warning' })
                return
            }

            const fileBase64: string = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onloadend = (evt) => {
                    if (evt?.target?.readyState === FileReader.DONE) {
                        resolve(evt?.target?.result as string)
                    }
                }
            })

            setUploadedLogo(file)
            setCampaign({ ...campaign, logoBase64: String(fileBase64) })
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao tentar fazer upload da imagem.', { variant: 'warning' })
        }
    }

    const handleRemoveLogo = (e: React.MouseEvent) => {
        e.stopPropagation()
        setUploadedLogo(undefined)
        setCampaign({
            ...campaign,
            logoBase64: null,
            logoUrl: undefined
        })
    }

    return (
        <>
            <Grid container justifyContent="space-between">
                <TextField
                    label="Buscar campanha" size="small" color="primary" variant="outlined"
                    value={searchInput}
                    onChange={({ target }) => setSearchInput(target.value)}
                />
                <Button color="primary" variant="contained" onClick={() => openCampaignDialog()}>
                    Criar nova campanha
                </Button>
            </Grid>

            <Divider size={3} />

            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Título</TableCell>
                                <TableCell align="right">Descrição</TableCell>
                                <TableCell align="right">Início da Campanha</TableCell>
                                <TableCell align="right">Fim da Campanha</TableCell>
                                <TableCell align="right">Hora de Início</TableCell>
                                <TableCell align="right">Hora de Fim</TableCell>
                                <TableCell align="right">Dias da Semana</TableCell>
                                <TableCell align="right">Rádios</TableCell>
                                <TableCell align="right">Campanha Ativa</TableCell>
                                <TableCell align="right">Criado em</TableCell>
                                <TableCell align="right">Atualizado em</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                campaigns.filter(campaign => (!campaign?.title && !searchInput) || campaign?.title?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                                    .map((campaign) => (
                                        <TableRow key={campaign?.uuid}>
                                            <TableCell component="th" scope="row">{campaign?.title}</TableCell>
                                            <TableCell align="right" size="small" className={classes.descriptionColumn}>{campaign?.description}</TableCell>
                                            <TableCell align="right">{dayjs(campaign?.beginAt).format('DD/MM/YYYY HH:mm')}h</TableCell>
                                            <TableCell align="right">{dayjs(campaign?.finishAt).format('DD/MM/YYYY HH:mm')}h</TableCell>
                                            <TableCell align="right">{campaign?.hourStart?.slice(0, 5)}</TableCell>
                                            <TableCell align="right">{campaign?.hourEnd?.slice(0, 5)}</TableCell>

                                            <TableCell align="right">
                                                {
                                                    (campaign?.weekdays ?? []).length > 0
                                                        ? (campaign.weekdays ?? [])
                                                            .map(day => {
                                                                const dayLabel = weekDaysMap.find(item => item.value === day)?.label
                                                                return dayLabel ? dayLabel.charAt(0).toUpperCase() + dayLabel.slice(1, 3).toLowerCase() : ''
                                                            })
                                                            .join(', ')
                                                        : 'Nenhum dia selecionado'
                                                }
                                            </TableCell>

                                            <TableCell align="right" className={classes.descriptionColumn}>{campaign?.companies?.length === companies.length ? 'Todas' : campaign?.companies?.map(c => c.name).join(', ')}</TableCell>
                                            <TableCell align="right">
                                                <Switch checked={campaign?.enabled} onClick={() => handleChangeCampaignEnabled(campaign)} color="primary" />
                                            </TableCell>
                                            <TableCell align="right">{dayjs(campaign?.createdAt).format('DD/MM/YYYY HH:mm')}h</TableCell>
                                            <TableCell align="right">{dayjs(campaign?.updatedAt).format('DD/MM/YYYY HH:mm')}h</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => copyPublicCampaignMetricsLink(campaign)}>
                                                    <CopyPublicLinkIcon />
                                                </IconButton>

                                                <IconButton onClick={() => openCampaignDialog(campaign)}>
                                                    <EditIcon />
                                                </IconButton>

                                                <Link href={`/campaigns/${campaign.uuid}`}>
                                                    <IconButton>
                                                        <AccessCampaignIcon />
                                                    </IconButton>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog open={openedCampaignDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    {!campaign?.uuid ? 'Criando nova ' : 'Editando a '}campanha
                </DialogTitle>
                <Grid container className={classes.campaignDialogContainer} >
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            className={classes.itemInput}
                            label="Título" size="small" color="primary" variant="outlined"
                            value={campaign.title}
                            onChange={({ target }) => setCampaign({ ...campaign, title: target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={campaign.enabled}
                                    onChange={({ target }) => setCampaign({ ...campaign, enabled: target.checked })}
                                    color="primary"
                                />
                            }
                            label={'Campanha Ativa'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogDateItemInput}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                            <DatePicker
                                label="Início"
                                className={classes.fromInput}
                                minDate={dayjs()}
                                onChange={(value) => setCampaign({ ...campaign, beginAt: value?.toISOString() })}
                                value={campaign.beginAt ? dayjs(campaign.beginAt) : null}
                                disablePast
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 45
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 12px) scale(1)',
                                        '&.MuiInputLabel-shrink': {
                                            transform: 'translate(14px, -6px) scale(0.75)'
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogDateItemInput}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                            <DatePicker
                                label="Fim"
                                disabled={!campaign.beginAt}
                                minDate={dayjs(campaign.beginAt)}
                                onChange={(value) => setCampaign({ ...campaign, finishAt: value?.toISOString() })}
                                value={campaign.finishAt ? dayjs(campaign.finishAt) : null}
                                disablePast
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 45
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 12px) scale(1)',
                                        '&.MuiInputLabel-shrink': {
                                            transform: 'translate(14px, -6px) scale(0.75)'
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogDateItemInput}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                            <TimePicker
                                label="Hora incial"
                                className={classes.fromInput}
                                onChange={(value) => setCampaign({ ...campaign, hourStart: value?.format('HH:mm') })}
                                value={campaign.hourStart ? dayjs(campaign.hourStart, 'HH:mm') : null}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 45
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 12px) scale(1)',
                                        '&.MuiInputLabel-shrink': {
                                            transform: 'translate(14px, -6px) scale(0.75)'
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogDateItemInput}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                            <TimePicker
                                ampm={false}
                                disabled={!campaign.hourStart}
                                label="Hora Final"
                                minTime={dayjs(campaign.hourStart, 'HH:mm').add(5, 'minutes')}
                                onChange={(value) => setCampaign({ ...campaign, hourEnd: value?.format('HH:mm') })}
                                value={campaign.hourEnd ? dayjs(campaign.hourEnd, 'HH:mm') : null}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 45
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 12px) scale(1)',
                                        '&.MuiInputLabel-shrink': {
                                            transform: 'translate(14px, -6px) scale(0.75)'
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.dialogInputFile}
                        onClick={() => document.getElementById('logo-selector')?.click()}
                    >
                        <Button
                            fullWidth
                            color="primary"
                            variant="text"
                        >
                            ESCOLHER LOGO
                            {uploadedLogo ? ` (${uploadedLogo.name})` : ''}
                            <input
                                id="logo-selector"
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                onChange={handleSelectLogo}
                                multiple={false}
                                style={{ display: 'none' }}
                            />
                        </Button>
                        {(uploadedLogo || campaign.logoUrl) && (
                            <div className={classes.dialogLogo}>
                                <CloseIcon className={classes.closeIcon} onClick={(e) => handleRemoveLogo(e)} />
                                <img
                                    src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : campaign.logoUrl}
                                    alt="Logo Preview"
                                    className={classes.dialogLogoImg}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.dialogSelectItemInput}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">Rádios para veicular</InputLabel>
                            <Select
                                value={campaign.companyNames}
                                onChange={handleAddCompanyToCampaign}
                                variant="outlined"
                                color="primary"
                                multiple
                                renderValue={(selected: string[]) => selected.join(', ')}
                                label="Rádios para veicular"
                                className={classes.select}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224, // 48 * 4.5 + 8,
                                            width: 250
                                        }
                                    }
                                }}
                            >
                                {
                                    companies.map((company) => (
                                        <MenuItem key={company.uuid} value={company.uuid}>
                                            <Checkbox color='primary' checked={campaign?.companyUuids!.indexOf(company.uuid) > -1} />
                                            <ListItemText primary={getCompanyIdentifier(company)} />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid style={{ display: campaign.beginAt && campaign.finishAt ? 'flex' : 'none' }} item xs={12} sm={12} className={classes.dialogSelectItemInput}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">Dias da semana</InputLabel>
                            <Select
                                value={campaign.weekdays ?? []}
                                onChange={handleDayChange}
                                variant="outlined"
                                color="primary"
                                multiple
                                renderValue={(selected: any) => {
                                    const selectedLabels = selected.map((value: string) => {
                                        const dayLabel = weekDaysMap.find(day => day.value === value)?.label
                                        return dayLabel || value
                                    })
                                    return selectedLabels.join(', ')
                                }}
                                label="Dias da semana"
                                className={classes.select}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224, // 48 * 4.5 + 8,
                                            width: 250
                                        }
                                    }
                                }}
                            >
                                {weekDays.map((day) => (
                                    <MenuItem key={day.value} value={day.value}>
                                        <Checkbox color='primary' checked={campaign.weekdays?.includes(day.value)} />
                                        <ListItemText primary={`${day.label}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.dialogItemInput}>
                        <TextField
                            type="textarea"
                            className={classes.itemInput}
                            label="Descrição" size="small" color="primary" variant="outlined"
                            value={campaign.description}
                            onChange={({ target }) => setCampaign({ ...campaign, description: target.value })}
                            multiline rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.dialogItemInput}>
                        <TextField
                            label="Adicione o whatsapp e pressione Enter"
                            variant="outlined"
                            className={classes.itemInput}
                            size="small"
                            color="primary"
                            onKeyDown={handleKeyDown}
                            placeholder="Digite o número"
                        />
                        <Grid container gap={1} mt={2}>
                            {(campaign?.whatsappReportPhonenumbers || []).map((number, index) => (
                                <Chip
                                    key={index}
                                    label={number}
                                    onDelete={() => handleDelete(number)}
                                    color="primary"
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Divider size={2} />

                <DialogActions>
                    <Button onClick={handleCloseCampaignDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={saveCampaign} color="primary" autoFocus variant="contained"
                        endIcon={loadingCampaign && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    >
                        {campaign.uuid ? 'Salvar' : 'Criar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Campaigns
