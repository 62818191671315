import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    companyDialogContainer: {
        // padding: theme.spacing(3)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    filterEnabledCheckbox: {
        marginLeft: theme.spacing(3)
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        width: '100%'
    },
    formControlSelect: {
        width: '14rem'
    },
    selectInput: {
        marginTop: '-7px'
    },
    select: {
        height: theme.spacing(5)
    },
    rangeInputLabel: {
        color: theme.palette.secondary.light
    },
    dialogInputFile: {
        margin: theme.spacing(1),
        border: `.5px solid ${theme.palette.primary.light}`
    },
    closeIcon: {
        position: 'absolute',
        right: 4,
        top: 4,
        color: theme.palette.grey[700],
        cursor: 'pointer'
    },
    dialogLogo: {
        marginBottom: '10px',
        height: '200px',
        textAlign: 'center',
        position: 'relative',
        width: 'fit-content',
        margin: '0 auto'
    },
    dialogLogoImg: {
        maxWidth: '100%',
        maxHeight: '200px',
        objectFit: 'cover',
        borderRadius: '4px'
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}))

export default useStyles
