import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    TextField,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Checkbox,
    ListItemText,
    Divider,
    Typography,
    IconButton,
    Box,
    useTheme,
    CircularProgress,
} from '@mui/material';
import { debounce } from 'lodash';
import { IRadio } from '@/pages/Intranet/Apps';
import useStyles from '@/pages/Intranet/Apps/style';
import IconSelector from '../IconSearch';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditOutlined as EditIcon, DeleteOutline as DeleteIcon } from "@mui/icons-material";
import ApiService from '@/services/Api';
import { useSnackbar } from 'notistack';

export interface FormData {
    app: {
        uuid?: string;
        backgroundColor?: string;
        mainColor?: string;
        secondaryColor?: string;
        textColor?: string;
        companyUuids: string[];
    };
    barButtons: {
        [companyUuid: string]: {
            icon: IconDefinition;
            url: string;
        }[];
    };
}

interface MultiStepDialogProps {
    open: boolean;
    onClose: () => void;
    loadApps: () => void;
    initialFormData: FormData;
    radios: IRadio[]
}

const debouncedUpdate = debounce((callback) => callback(), 300)

const MultiStepDialog: React.FC<MultiStepDialogProps> = ({ open, onClose, loadApps, initialFormData, radios }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [companies, setCompanies] = useState<string[]>(initialFormData.app.companyUuids ?? []);

    const [selectedIcon, setSelectedIcon] = useState<IconDefinition | null>(null);
    const [currentUrl, setCurrentUrl] = useState<string>("");
    const [selectedCompany, setSelectedCompany] = useState<string>("");
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const steps = [
        'Informações do aplicativo',
        'Configuração dos botões',
    ];

    useEffect(() => {
        setFormData(initialFormData);
        setCompanies(initialFormData.app.companyUuids)
    }, [initialFormData])

    const handleChange = (event: SelectChangeEvent<typeof companies>) => {
        const {
            target: { value },
        } = event;
        const newValue = Array.isArray(value) ? value : value.split(",");
        setCompanies(newValue);
    };

    const handleNext = () => {
        if (validateCurrentStep()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSelectedCompany("");
        setSelectedIcon(null);
        setCurrentUrl("");
    };

    const handleClose = () => {
        onClose();
        handleResetData();
        setActiveStep(0);
    };

    const validateCurrentStep = (): boolean => {
        switch (activeStep) {
            case 0:
                if (!companies || companies.length === 0) {
                    enqueueSnackbar("Selecione ao menos uma empresa.", { variant: "error" });
                    return false;
                }
                const filteredBarButtons = Object.keys(formData.barButtons)
                    .filter(companyUuid => companies.includes(companyUuid))
                    .reduce((acc, companyUuid) => {
                        acc[companyUuid] = formData.barButtons[companyUuid];
                        return acc;
                    }, {} as typeof formData.barButtons);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    app: {
                        ...prevFormData.app,
                        companyUuids: companies,
                    },
                    barButtons: filteredBarButtons
                }))
                break;

        }
        return true;
    };

    const handleSubmit = async () => {
        if (validateCurrentStep()) {
            try {
                setLoading(true);
                const { app, barButtons } = formData;

                const appData = {
                    ...app,
                    barButtons
                };

                const endpoint = app.uuid ? `/app/${app.uuid}` : "/app";
                const method = app.uuid ? ApiService.put : ApiService.post;

                const response = await method(endpoint, appData);

                if (response.data) {
                    enqueueSnackbar("Aplicativo salvo com sucesso.", { variant: "success" });
                    handleResetData();
                    handleClose();
                    loadApps();
                }

            } catch (error: any) {
                console.error("Erro ao salvar aplicativo:", error);
                enqueueSnackbar(`${error.data}`, { variant: "error" });
            } finally {
                setLoading(false);
            }
        }
    };

    const updateAppColor = (colorKey: string, value: string) => {
        debouncedUpdate(() => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                app: {
                    ...prevFormData.app,
                    [colorKey]: value,
                },
            }));
        });
    }

    const handleSaveIcon = () => {
        if (!selectedCompany || !selectedIcon || !currentUrl) return enqueueSnackbar("Preencha todos os campos.", { variant: "error" });

        const companyButtons = formData.barButtons[selectedCompany] ?? [];

        if (companyButtons.length >= 5 && selectedIndex === -1) {
            return enqueueSnackbar("Cada rádio pode ter no máximo 5 botões.", { variant: "error" });
        }

        setFormData((prevFormData) => {
            const { barButtons } = prevFormData;
            const companyButtons = barButtons[selectedCompany] ?? [];

            const updatedButtons = selectedIndex === -1
                ? [...companyButtons, { icon: selectedIcon, url: currentUrl }]
                : companyButtons.map((button, index) => (
                    index === selectedIndex
                        ? { icon: selectedIcon, url: currentUrl }
                        : button
                ));

            return {
                ...prevFormData,
                barButtons: {
                    ...barButtons,
                    [selectedCompany]: updatedButtons,
                },
            };
        });

        setSelectedIcon(null);
        setCurrentUrl("");
        setSelectedIndex(-1);
    };

    const handleResetData = () => {
        setSelectedCompany("");
        setSelectedIcon(null);
        setCurrentUrl("");
        setSelectedIndex(-1);
    }

    const handleEdit = (index: number) => {
        const button = formData.barButtons[selectedCompany][index];
        setSelectedIcon(button.icon);
        setCurrentUrl(button.url);
        setSelectedIndex(index);
    };

    const handleIconSelect = (icon: IconDefinition) => {
        if (!selectedCompany) return
        setSelectedIcon(icon);
    };

    const handleDelete = (index: number) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            barButtons: {
                ...prevFormData.barButtons,
                [selectedCompany]: prevFormData.barButtons[selectedCompany].filter((_, i) => i !== index),
            },
        }));
    };

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Cor de fundo"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.backgroundColor}
                                onChange={(e) => {
                                    updateAppColor("backgroundColor", e.target.value)
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Cor principal"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.mainColor}
                                onChange={(e) => {
                                    updateAppColor("mainColor", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Cor secundária"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.secondaryColor}
                                onChange={(e) => {
                                    updateAppColor("secondaryColor", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Cor de texto"
                                size="small"
                                variant="outlined"
                                type="color"
                                value={formData.app.textColor}
                                onChange={(e) => {
                                    updateAppColor("textColor", e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                        >
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size="small"
                                style={{ width: 250 }}
                            >
                                <InputLabel id="associatedRadios">Rádios Associadas</InputLabel>
                                <Select
                                    labelId="associatedRadios"
                                    multiple
                                    value={companies}
                                    variant="outlined"
                                    onChange={handleChange}
                                    label="Rádios Associadas"
                                    renderValue={(selected) =>
                                        selected
                                            .map((uuid) => {
                                                const radio = radios.find(
                                                    (r) => r.uuid === uuid
                                                );
                                                return radio
                                                    ? `${radio.name} - ${radio.address.city}`
                                                    : "";
                                            })
                                            .join(", ")
                                    }
                                    MenuProps={MenuProps}
                                    className={classes.select}
                                >
                                    {radios.map((radio) => (
                                        <MenuItem
                                            key={radio.uuid}
                                            value={radio.uuid}
                                        >
                                            <Checkbox
                                                checked={
                                                    companies.indexOf(
                                                        radio.uuid
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={`${radio.name} - ${radio.address.city}`}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                );
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                        >
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                size="small"
                                style={{ width: 250 }}
                            >
                                <InputLabel id="selectedCompany">Rádio Associada</InputLabel>
                                <Select
                                    labelId="selectedCompany"
                                    value={selectedCompany}
                                    variant="outlined"
                                    onChange={(e) => setSelectedCompany(e.target.value)}
                                    label="Rádio Associada"
                                    renderValue={(selected) => {
                                        const radio = radios.find(
                                            (r) => r.uuid === selected
                                        );
                                        return radio
                                            ? `${radio.name} - ${radio.address.city}`
                                            : "";
                                    }
                                    }
                                    MenuProps={MenuProps}
                                    className={classes.select}
                                >
                                    {companies.map((uuid) => {
                                        const radio = radios.find(
                                            (r) => r.uuid === uuid
                                        );
                                        return (
                                            <MenuItem
                                                key={uuid}
                                                value={uuid}
                                            >
                                                {radio?.name} - {radio?.address.city}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            {selectedCompany && (
                                <IconSelector onSelect={handleIconSelect} />
                            )}

                            {selectedIcon && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                                >

                                    <FontAwesomeIcon
                                        icon={selectedIcon}
                                        size="2x"
                                    />

                                    <TextField
                                        label="URL"
                                        variant="outlined"
                                        value={currentUrl}
                                        onChange={(e) => setCurrentUrl(e.target.value)}
                                        fullWidth
                                        size="small"
                                    />


                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleResetData}
                                        sx={{ px: theme.spacing(3) }}
                                    >
                                        Descartar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveIcon}
                                        sx={{ px: theme.spacing(3) }}
                                    >
                                        Salvar
                                    </Button>
                                </Grid>

                            )}

                            <Divider sx={{ mt: theme.spacing(2) }} />

                            <Grid
                                item
                                xs={12}
                                sm={12}
                            >
                                {formData.barButtons[selectedCompany] && formData.barButtons[selectedCompany].map((button, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex', alignItems: 'center', gap:
                                                theme.spacing(2), justifyContent: 'space-between', mt: theme.spacing(1), mb: theme.spacing(1)
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={button.icon}
                                            size="2x"
                                        />

                                        <Typography variant="body1">
                                            {button.url}
                                        </Typography>

                                        <Box>
                                            <IconButton onClick={() => handleEdit(index)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                            </Grid>

                        </Grid>
                    </Grid>
                );
            default:
                return 'Etapa desconhecida';
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Formulário de {formData.app.uuid ? 'Edição' : 'Cadastro'}
            </DialogTitle>

            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <DialogContent>
                {renderStepContent(activeStep)}
            </DialogContent>

            <DialogActions>
                {activeStep === 0 && (
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                )}
                {activeStep !== 0 && (
                    <Button onClick={handleBack} color="primary" disabled={loading}>
                        Voltar
                    </Button>
                )}
                {activeStep === steps.length - 1 ? (
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        endIcon={loading && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                    >
                        Salvar
                    </Button>
                ) : (
                    <Button
                        onClick={handleNext}
                        color="primary"
                        variant="contained"
                    >
                        Próximo
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default MultiStepDialog;